.Form {
  margin: 10% 30%;
}

.buttons {
  margin: 10px;
}

.addButton {
  margin-right: 30px;
}

.emailSent {
  color: green;
  text-align: center;
  font-size: large;
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: local('Roboto Semilight'), local('Roboto');
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.modalButtonConv {
  margin-left: 20px;
}

.formSearch {
  margin-bottom: 50px;
}

a.conversationLink:link {
  color: white;
  text-decoration: none;
}

.app {
  width: 100vv;
  height: 100vh;
  overflow-y: hidden;

  .translate {
    translate: 'no';
  }

  .header {
    width: 100%;
    border-bottom: 1px solid gainsboro;
    text-align: center;
  }

  .date {
    color: grey;
    font-size: 10px;
  }

  .loading {
    width: 100%;
    text-align: center;
  }

  .mailReader {
    width: 100%;
    display: flex;
    height: calc(100% - 100px);

    .messageBox {
      flex: 0.4;
      overflow-x: hidden;
      overflow-y: auto;
      height: 95%;
      border-right: 2px solid gainsboro;

      .shippingMessage {
        width: 100%;
        padding: 2px 10px;
        border-bottom: 1px solid rgb(150, 150, 255);
      }

      .message {
        width: 100%;
        padding: 2px 10px;
        border-bottom: 1px solid rgb(150, 150, 255);

        cursor: pointer;

        &:hover {
          background-color: #eeeeee;
        }

        .date {
          color: grey;
          font-size: 10px;
        }

        .from {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .subject {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .message-active,
      .message-right-active,
      .message-unread-active {
        width: 100%;
        padding: 2px 10px;
        border-bottom: 1px solid rgb(150, 150, 255);
        background-color: #9dd5f5;

        cursor: pointer;

        &:hover {
          background-color: #eeeeee;
        }

        .date {
          color: grey;
          font-size: 10px;
        }

        .from {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .subject {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .message-unread {
        width: 100%;
        padding: 2px 10px;
        border-bottom: 1px solid rgb(150, 150, 255);

        cursor: pointer;

        &:hover {
          background-color: #eeeeee;
        }

        .date {
          color: grey;
          font-size: 10px;
          font-weight: 500;
        }

        .from {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 500;
        }

        .subject {
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 500;
        }
      }

      .message-right {
        width: 100%;
        padding: 2px 10px;
        border-bottom: 1px solid rgb(150, 150, 255);
        text-align: right;

        cursor: pointer;

        &:hover {
          background-color: #eeeeee;
        }

        .date {
          color: grey;
          font-size: 10px;
        }

        .from {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .subject {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .messageReader {
      flex: 0.6;
      overflow-x: hidden;
      overflow-y: auto;
      height: 100%;
      padding: 30px;

      .loading {
        width: 100%;
        font-size: 20px;
        align-items: center;
        padding: 20px;
      }
    }
  }
}
